import { ApolloErrorHandler } from 'setup/providers/ApolloClient';

import { errorCodes } from '../../../../shared/constants';

import { getErrorCode } from './getErrorCode';

const defaultOnError: ApolloErrorHandler = (
  enqueueSnackbar,
  { graphQLErrors, networkError, operation },
  closeSnackbarAction,
) => {
  // Handle network errors
  if (networkError) {
    enqueueSnackbar(getErrorCode(networkError), { variant: 'error' });
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
    return;
  }

  // No GraphQL errors to process
  if (!graphQLErrors || graphQLErrors.length === 0) return;

  // Check for forbidden error with ignore flag
  const firstError = graphQLErrors[0];
  const { ignoreForbiddenError } = (operation.getContext() || {}) as {
    ignoreForbiddenError?: boolean;
  };

  if (firstError.extensions?.code === errorCodes.forbidden && ignoreForbiddenError) {
    return;
  }

  // Prepare error messages
  const errorMessages = graphQLErrors.map(error => {
    const { message, extensions, path, locations } = error;

    // Construct a detailed error message
    let detailedMessage = message;

    // Include error code if available
    if (extensions?.code) {
      detailedMessage += ` (Code: ${extensions.code})`;
    }

    // Include path and location information for debugging
    if (path) {
      detailedMessage += ` - Path: ${path.join('.')}`;
    }
    if (locations) {
      detailedMessage += ` - Locations: ${JSON.stringify(locations)}`;
    }

    return detailedMessage;
  });

  // Limit the number of displayed errors to prevent overwhelming the user
  const displayErrors = errorMessages.slice(0, 5);

  // Show errors to the user
  enqueueSnackbar(displayErrors.join('\n'), {
    variant: 'error',
    style: { whiteSpace: 'pre-line' },
    persist: true, // Keep the error visible
    action: closeSnackbarAction, // Optional close action
  });

  // Always log full error details for debugging
  // eslint-disable-next-line no-console
  console.error('[GraphQL Errors]:', graphQLErrors);
};

export { defaultOnError };
