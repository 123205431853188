import { NonReplenStatus, Option } from 'shared/types';

const nonReplenStatusOptions: Option<string, string>[] = [
  { value: NonReplenStatus.Alc, label: NonReplenStatus.Alc },
  { value: NonReplenStatus.Anr, label: NonReplenStatus.Anr },
  { value: NonReplenStatus.Brk, label: NonReplenStatus.Brk },
  { value: NonReplenStatus.Bul, label: NonReplenStatus.Bul },
  { value: NonReplenStatus.New, label: NonReplenStatus.New },
  { value: NonReplenStatus.Sea, label: NonReplenStatus.Sea },
  { value: NonReplenStatus.Tvc, label: NonReplenStatus.Tvc },
  { value: NonReplenStatus.Tvo, label: NonReplenStatus.Tvo },
  { value: NonReplenStatus.Sip, label: NonReplenStatus.Sip },
];

const assortmentOverrideInactiveNonReplenStatusOptions: Option<string, string>[] = [
  { value: NonReplenStatus.Dnr, label: NonReplenStatus.Dnr },
  { value: '', label: NonReplenStatus.Empty },
];

export { nonReplenStatusOptions, assortmentOverrideInactiveNonReplenStatusOptions };
