import { isNil } from 'ramda';

import { UpdateDestinationAssortmentsMutationVariables } from 'shared/types';
import { CostFields, CurrencyFields, Row } from 'pages/DestinationAssortment/types';

const selectCost = (
  costFieldName: CostFields,
  currencyFieldName: CurrencyFields,
  updateChunk: Record<string, unknown>,
  row: Row,
): number | undefined => {
  const newCost = updateChunk[costFieldName] as number | undefined | null;
  const updatingCost = Object.hasOwnProperty.call(updateChunk, costFieldName);
  const currentCost = row[costFieldName];
  const currentCurrency = row[currencyFieldName];
  const nextCurrencyId = updateChunk[currencyFieldName];

  if (updatingCost) {
    return Number(newCost) ?? 0;
  }

  if ((currentCurrency?.id || nextCurrencyId) && isNil(currentCost)) {
    return 0;
  }

  return currentCost;
};

const mapRowToMutationArgs = (
  row: Row,
  updatedChunk: Record<string, unknown>,
): UpdateDestinationAssortmentsMutationVariables => {
  const mappedUpdatedChunk = updatedChunk;

  if (updatedChunk.selectedPackagingID) {
    mappedUpdatedChunk.packagingID = updatedChunk.selectedPackagingID;

    delete mappedUpdatedChunk.selectedPackagingID;
  }

  return {
    input: {
      assortments: [
        {
          id: row.id,
          maxAllocationQuantity: row.maxAllocationQuantity,
          minAllocationQuantity: row.minAllocationQuantity,
          nonReplenStatus: row.nonReplenStatus,
          assortmentOverrideState: row.assortmentOverrideState,
          packagingID: row.selectedPackagingID,
          localCostCurrency: row.localCostCurrency?.id,
          nationalCostCurrency: row.nationalCostCurrency?.id,
          velocityCategory: row.velocityCategory?.id,
          ...mappedUpdatedChunk,
          localCost: selectCost('localCost', 'localCostCurrency', mappedUpdatedChunk, row),
          nationalCost: selectCost('nationalCost', 'nationalCostCurrency', mappedUpdatedChunk, row),
        },
      ],
    },
  };
};

export { mapRowToMutationArgs };
