import { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';

import { memoCell } from 'shared/utils';
import { OptionValueType, Select, renderEmptyPlaceholder } from 'shared/components/Select';
import { Option } from 'shared/types/props';
import { MockCellValue } from 'shared/components/DataGrid/components/Cells/types';

import { shouldDisable } from '../utils';

import { FactoryCellSelectProps } from './types';

const FactoryCellSelect = <
  DataType extends object,
  CellValue extends OptionValueType = OptionValueType,
  ObjectValue extends object | CellValue = CellValue,
>(
  cellArguments: {
    options?: Option<CellValue>[];
    isDisabled?: (rowData: DataType) => boolean;
    isHidden?: (rowData: DataType) => boolean;
    pickValue?: (value: ObjectValue) => CellValue | null;
    dependencies?: (keyof DataType)[];
    setValueToRowState?: boolean;
    deriveOptionsFromRow?: (rowData: DataType) => Option<CellValue>[];
    showError?: (rowData: DataType) => boolean;
    filterOptions?: (rowData: DataType) => Option<CellValue>[];
  } = { dependencies: [], setValueToRowState: false },
) =>
  memoCell<FactoryCellSelectProps<DataType, CellValue, ObjectValue>>(cellProps => {
    const {
      column: { id: columnId },
      row,
      updateGridData,
      value: initialValue,
    } = cellProps;
    const {
      deriveOptionsFromRow,
      isDisabled,
      options,
      pickValue,
      setValueToRowState,
      showError,
      isHidden,
      filterOptions,
    } = cellArguments;
    const { index, original, setState } = row;
    if (isHidden && isHidden(original)) return null;

    const error = showError?.(row.original);
    const disabled = shouldDisable(cellProps, isDisabled);
    const mappedOptions =
      options ||
      filterOptions?.(row.original) ||
      (deriveOptionsFromRow?.(original) as Option<CellValue>[]);

    const { isOpen } = (initialValue ?? {}) as MockCellValue<ObjectValue>;
    const derivedInitialValue =
      ((initialValue ?? {}) as MockCellValue<ObjectValue>).value ?? initialValue;

    const mapInitialValue =
      (pickValue ? pickValue(derivedInitialValue) : (derivedInitialValue as CellValue)) ??
      ('' as CellValue);

    const [value, setValue] = useState(mapInitialValue);
    useEffect(() => {
      if (setValueToRowState) {
        setState({ [columnId]: value });
      }
    }, [value, setState, setValueToRowState]);

    const handleChange = useCallback(
      ({ target: { value: selectedValue } }: SelectChangeEvent<CellValue>) => {
        setValue(selectedValue as CellValue);
        updateGridData(index, columnId, selectedValue as CellValue, original, row);
      },
      [updateGridData, index, columnId, setValue, original, row],
    );

    const disabledRenderValue = useMemo(() => {
      if (disabled) {
        if (value === null) {
          return renderEmptyPlaceholder;
        }
        const valueText = mappedOptions.find(
          ({ value: optionValue }) => optionValue === value,
        )?.label;
        return valueText ? () => valueText : renderEmptyPlaceholder;
      }
      return null;
    }, [disabled, mappedOptions, value]);

    if (disabled) {
      return <Select disabled options={[]} renderValue={disabledRenderValue!} value="" />;
    }

    return (
      <Select<CellValue>
        disabled={disabled}
        error={error}
        onChange={handleChange}
        open={isOpen}
        options={mappedOptions}
        value={value}
      />
    );
  }, cellArguments.dependencies);

export { FactoryCellSelect };
