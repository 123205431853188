import { AssortmentOverrideState, Option } from 'shared/types';

const assortmentOverrideStateOptions: Option<string, string>[] = [
  { value: AssortmentOverrideState.Active, label: AssortmentOverrideState.Active },
  { value: AssortmentOverrideState.Inactive, label: AssortmentOverrideState.Inactive },
  { value: AssortmentOverrideState.Primary, label: AssortmentOverrideState.Primary },
  { value: AssortmentOverrideState.NonReplen, label: AssortmentOverrideState.NonReplen },
];

export { assortmentOverrideStateOptions };
